import './styles/loader.css';

function Loader() {
    return (
        <div className='loader'>
        Loading....
        </div>
    );
}

export default Loader;
